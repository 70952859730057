<template>
  <CardForm :title="store.storeName" :subTitle="store.address">
    <template #form>
      <div class="card-body">
        <b-row>
          <b-col cols="12">
            <PlainText label="Nomor Transaksi">
              <template #value>
                <span class="font-weight-bolder">
                  {{ form.transactionNumber }}
                </span>
              </template>
            </PlainText>
          </b-col>
          <b-col cols="12">
            <PlainText
              label="Tanggal Transaksi"
              :value="dateTimeFormat(form.transactionDate)"
            />
          </b-col>
          <b-col cols="12">
            <PlainText label="Pelanggan" :value="form.customer?.label" />
          </b-col>
          <b-col cols="12">
            <PlainText label="Kasir" :value="form.created?.createdBy" />
          </b-col>
        </b-row>
        <hr class="card-separator" />
        <b-row>
          <b-col cols="12" v-for="(cart, i) in form.carts" v-bind:key="i">
            <span class="font-size-h6 font-weight-bolder">{{
              cart.productName
            }}</span>
            <PlainText
              :label="`${numberFormat(cart.quantity)} x ${numberFormat(
                cart.price
              )}`"
            >
              <template #value>
                <span
                  v-if="cart.discount > 0"
                  class="text-decoration-line-through mr-2"
                >
                  {{ numberFormat(cart.fixedPrice) }}
                </span>
                <span>{{ numberFormat(cart.fixedPrice - cart.discount) }}</span>
              </template>
            </PlainText>
          </b-col>
        </b-row>
        <hr class="card-separator" />
        <b-row>
          <b-col cols="12">
            <PlainText label="Sub Total" :value="numberFormat(form.subTotal)" />
          </b-col>
          <b-col v-if="store.taxPercentage > 0" cols="12">
            <PlainText
              :label="`Pajak (${store.taxPercentage}%)`"
              :value="numberFormat(form.tax)"
            />
          </b-col>
          <b-col cols="12">
            <PlainText label="Diskon" :value="numberFormat(form.discount)" />
          </b-col>
          <b-col cols="12">
            <PlainText
              label="Grand Total"
              :value="numberFormat(form.grandTotal)"
            />
          </b-col>
        </b-row>
        <hr class="card-separator" />
        <b-row>
          <b-col cols="12">
            <PlainText
              label="Total Transaksi"
              :value="numberFormat(form.grandTotal)"
            />
          </b-col>
          <b-col cols="12">
            <PlainText
              :label="form.paymentMethod?.label"
              :value="numberFormat(form.paymentAmount)"
            />
          </b-col>
          <b-col v-if="form.paymentChange > 0" cols="12">
            <PlainText
              label="Kembalian"
              :value="numberFormat(form.paymentChange)"
            />
          </b-col>
          <b-col v-if="form.paymentRemaining > 0" cols="12">
            <PlainText
              label="Kurang Bayar"
              :value="numberFormat(form.paymentRemaining)"
            />
          </b-col>
        </b-row>
      </div>
    </template>
  </CardForm>
</template>

<script>
import { mapGetters } from "vuex";
import { dateTimeFormat, numberFormat } from "@/core/utils";

export default {
  props: {
    form: Object,
  },
  computed: {
    ...mapGetters(["store"]),
  },
  methods: {
    dateTimeFormat,
    numberFormat,
  },
};
</script>
