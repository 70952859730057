<template>
  <fragment>
    <b-row>
      <b-col cols="12" sm="12" md="12" lg="6">
        <CardForm :backButton="true" :title="title" :subTitle="subTitle">
          <template #toolbar>
            <b-button
              v-if="form.paymentRemaining > 0"
              variant="primary"
              @click="openModal"
            >
              Buat Pembayaran
            </b-button>
            <b-button v-else variant="success"> Lunas </b-button>
          </template>
          <template #form>
            <div class="card-body">
              <template v-if="payments.length > 0">
                <fragment v-for="(payment, i) in payments" v-bind:key="i">
                  <hr v-if="i > 0" class="card-separator" />
                  <b-row>
                    <b-col cols="12">
                      <PlainText label="Pembayaran Ke">
                        <template #value>
                          <span class="font-size-h5 font-weight-bolder"
                            >#{{ payments.length - i }}</span
                          >
                        </template>
                      </PlainText>
                    </b-col>
                    <b-col cols="12">
                      <PlainText
                        label="Tanggal Bayar"
                        :value="dateFormat(payment.paymentDate)"
                      />
                    </b-col>
                    <b-col cols="12">
                      <PlainText
                        label="Jumlah Bayar"
                        :value="numberFormat(payment.amount)"
                      />
                    </b-col>
                    <b-col cols="12">
                      <PlainText
                        label="Kurang Bayar"
                        :value="numberFormat(payment.paymentRemaining)"
                      />
                    </b-col>
                    <b-col cols="12">
                      <PlainText
                        label="Kasir"
                        :value="payment.created?.createdBy"
                      />
                    </b-col>
                  </b-row>
                </fragment>
              </template>
              <template v-else>
                <div
                  class="d-flex align-items-center justify-content-center text-center p-20"
                >
                  <div>
                    <div class="mb-3 mt-n5">
                      <span class="svg-icon svg-icon-10x svg-icon-danger">
                        <inline-svg src="media/svg/icons/General/Sad.svg" />
                      </span>
                    </div>
                    <h5>Belum ada pembayaran</h5>
                  </div>
                </div>
              </template>
            </div>
          </template>
        </CardForm>
      </b-col>
      <b-col cols="12" sm="12" md="12" lg="6">
        <TransactionDetail :form="form" />
      </b-col>
    </b-row>

    <b-modal
      v-model="modal.show"
      title="Pembayaran"
      :no-close-on-backdrop="true"
      :ok-only="true"
      ok-title="Selesaikan Pembayaran"
      @ok="handleSubmit"
    >
      <!-- :ok-disabled="paymentMethod?.group?.id != 3 && modal.amount < grandTotal" -->
      <div class="d-flex align-items-center mb-4">
        <div class="d-flex flex-column flex-grow-1">
          <span class="text-dark-75 font-size-lg font-weight-bold">
            Dilayani Oleh :
          </span>
        </div>
        <span class="text-dark-75 font-size-lg font-weight-bold">
          {{ user.fullName }}
        </span>
      </div>
      <div class="d-flex align-items-center mb-4">
        <div class="d-flex flex-column flex-grow-1">
          <span class="text-dark-75 font-size-lg font-weight-bold">
            Pelanggan :
          </span>
        </div>
        <span class="text-dark-75 font-size-lg font-weight-bold">
          {{ form.customer?.label }}
        </span>
      </div>

      <div class="d-flex align-items-center mb-4">
        <div class="d-flex flex-column flex-grow-1">
          <span class="text-dark-75 font-size-lg font-weight-bold">
            Tanggal Bayar :
          </span>
        </div>
        <span class="text-dark-75 font-size-lg font-weight-bold">
          <b-input v-model="modal.paymentDate" type="date" />
        </span>
      </div>

      <div class="d-flex align-items-center">
        <div class="d-flex flex-column flex-grow-1">
          <span class="text-dark-75 font-size-lg font-weight-bold">
            Metode Pembayaran :
          </span>
        </div>
      </div>
      <b-button
        v-for="paymentMethod in paymentMethods"
        v-bind:key="paymentMethod.id"
        :variant="
          modal.paymentMethodId == paymentMethod.id
            ? `success`
            : `outline-success`
        "
        size="sm"
        class="mr-2 mt-2 mb-4"
        @click="changePaymentMethod(paymentMethod)"
      >
        {{ paymentMethod.label }}
      </b-button>

      <div class="d-flex align-items-center">
        <div class="d-flex flex-column flex-grow-1">
          <span class="text-dark-75 font-size-lg font-weight-bold">
            Jumlah Yang Dibayar :
          </span>
        </div>
      </div>
      <b-button
        v-for="(fraction, i) in suggestions"
        v-bind:key="i"
        variant="outline-success"
        size="sm"
        class="mr-2 mt-2"
        @click="setUang(fraction)"
      >
        {{ fraction == 0 ? `Uang Pas` : setRupiah(fraction) }}
      </b-button>
      <vue-number
        v-model="modal.amount"
        v-bind="money"
        class="form-control mt-2 mb-4"
      />
      <div class="d-flex align-items-center">
        <div class="d-flex flex-column flex-grow-1">
          <span class="text-dark-75 font-size-lg font-weight-bold">
            Uang Kembalian :
          </span>
        </div>
        <span class="text-dark-75 font-size-lg font-weight-bold">
          {{ setRupiah(changeDue) }}
        </span>
      </div>
      <div class="d-flex align-items-center mt-4 mb-4">
        <div class="d-flex flex-column flex-grow-1">
          <span class="text-dark-75 font-size-lg font-weight-bold">
            Jumlah Sisa Bayar :
          </span>
        </div>
        <span class="text-dark-75 font-size-lg font-weight-bold">
          {{ setRupiah(remainingPayment) }}
        </span>
      </div>
    </b-modal>
  </fragment>
</template>

<script>
import { mapGetters } from "vuex";
// import { required } from "vuelidate/lib/validators";
import {
  getDate,
  dateFormat,
  dateTimeFormat,
  numberFormat,
  suggestions,
  setRupiah,
} from "@/core/utils";
import TransactionDetail from "@/view/pages/transaction/Detail.vue";

export default {
  components: {
    TransactionDetail,
  },
  data: () => ({
    title: "Pembayaran",
    subTitle: "Detail pembayaran",
    route: {
      form: "outstandingCreate",
      table: "outstanding",
    },
    form: {
      transactionNumber: null,
      transactionDate: null,
      customer: null,
      subTotal: null,
      taxPercentage: null,
      tax: null,
      discount: null,
      grandTotal: null,
      amount: null,
      paymentAmount: null,
      paymentChange: null,
      paymentRemaining: null,
      outstanding: null,
      paymentMethod: null,
      carts: null,
      created: null,
    },
    paymentMethods: [],
    payments: [],
    money: {
      decimal: ",",
      separator: ".",
      prefix: "Rp",
      suffix: "",
      precision: 0,
      nullValue: "",
      masked: false,
      reverseFill: false,
    },
    modal: {
      show: false,
      paymentDate: getDate(),
      paymentMethodId: null,
      amount: 0,
    },
    suggestions,
  }),
  /* validations: {
    form: {
      transactionDate: { required },
      expense: { required },
      quantity: { required },
      amount: { required },
    },
  }, */
  computed: {
    ...mapGetters("auth", ["user"]),
    ...mapGetters(["store"]),
    changeDue() {
      const self = this;
      let calculate = self.modal.amount - self.form.paymentRemaining;
      return calculate >= 0 ? calculate : 0;
    },
    remainingPayment() {
      const self = this;
      let calculate = self.form.paymentRemaining - self.modal.amount;
      return calculate >= 0 ? calculate : 0;
    },
  },
  created() {
    const self = this;

    self.getById();
    self.getPaymentByTransactionId();
    self.getPaymentMethods();
  },
  methods: {
    getDate,
    dateFormat,
    dateTimeFormat,
    numberFormat,
    setRupiah,
    openModal() {
      const self = this;

      if (self.form.paymentRemaining > 0) {
        self.modal.paymentDate = self.getDate();
        self.modal.paymentMethodId = null;
        self.modal.amount = 0;
        self.modal.show = true;
      }
    },
    setUang(fraction) {
      const self = this;
      self.modal.amount = fraction == 0 ? self.form.paymentRemaining : fraction;
    },
    changePaymentMethod(paymentMethod) {
      this.modal.paymentMethodId = paymentMethod.id;
    },
    getById() {
      const self = this;

      let loader = self.$loading.show();
      self.$store
        .dispatch("apis/get", {
          url: `/transaction/${self.store.id}/${self.$route.params.id}`,
        })
        .then((response) => {
          if (response.error) {
            self.$message.error({
              zIndex: 10000,
              message: response.message,
            });

            self.$router.push({ name: self.route.table });
          } else {
            self.form = {
              transactionNumber: response.data.transactionNumber,
              transactionDate: response.data.created?.createdAt,
              customer: response.data.customer,
              subTotal: response.data.subTotal,
              taxPercentage: response.data.taxPercentage,
              tax: response.data.tax,
              discount: response.data.discount,
              grandTotal: response.data.grandTotal,
              amount: response.data.amount,
              paymentAmount: response.data.paymentAmount,
              paymentChange: response.data.paymentChange,
              paymentRemaining: response.data.paymentRemaining,
              outstanding: response.data.outstanding,
              paymentMethod: response.data.paymentMethod,
              carts: response.data.carts,
              created: response.data.created,
            };
          }
        })
        .finally(() => loader.hide());
    },
    getPaymentByTransactionId() {
      const self = this;

      let loader = self.$loading.show();
      self.$store
        .dispatch("apis/get", {
          url: `/transaction/${self.store.id}/payment/${self.$route.params.id}`,
        })
        .then((response) => {
          if (response.error) {
            self.$message.error({
              zIndex: 10000,
              message: response.message,
            });

            self.$router.push({ name: self.route.table });
          } else {
            self.payments = response.data.data;
          }
        })
        .finally(() => loader.hide());
    },
    getPaymentMethods() {
      const self = this;

      self.$store
        .dispatch("apis/get", {
          url: `/store/${self.store.id}/payment-method`,
          params: {
            pageNumber: 1,
            pageSize: 0,
            actived: true,
            orderBy: "sequenceNumber, label",
          },
        })
        .then((response) => {
          if (response.error) {
            self.$message.error({
              zIndex: 10000,
              message: response.message,
            });
          } else {
            self.paymentMethods = response.data.data;
          }
        });
    },
    handleSubmit(event) {
      event.preventDefault();

      const self = this;

      if (self.form.paymentRemaining > 0) {
        self.$dialog
          .confirm("Kamu yakin mau menyelesaikan pembayaran ini ?", {
            okText: "Ya, Selesaikan",
            cancelText: "Ga jadi",
            loader: true,
          })
          .then((dialog) => {
            let payload = {
              transactionId: self.$route.params.id,
              paymentMethodId: self.modal.paymentMethodId,
              paymentDate: self.modal.paymentDate,
              paymentAmount: self.modal.amount,
            };

            self.$store
              .dispatch("apis/post", {
                url: `/transaction/${self.store.id}/payment`,
                params: payload,
              })
              .then((response) => {
                if (response.error) {
                  self.$message.error({
                    zIndex: 10000,
                    message: response.message,
                  });
                } else {
                  self.modal.paymentDate = null;
                  self.modal.paymentMethodId = null;
                  self.modal.amount = 0;
                  self.modal.show = false;
                  self.getById();
                  self.getPaymentByTransactionId();
                }
              })
              .finally(() => dialog.close());
          });
      }
    },
  },
};
</script>
